import React from 'react';
import {
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { useClientContext } from 'context/client.context';
import { Link, useParams } from 'react-router-dom';
import { privateRoutes } from 'router/routes';
import { Logout } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getUniqueKey } from 'utils';
import { useAuthContext } from 'context/auth.context';
import TenantSelector from 'components/TenantSelector/TenantSelector';
import { PermissionAction } from 'config/ability';
import { SideNavGroupType } from 'layout/Layout';
import {
  rawDocumentLink,
  useAdminMainLinks,
  useHeaderLinks,
  useMobileOnlyLinks,
  useUserMainLinks,
} from 'layout/layoutLinks';
import { Box } from '@mui/system';
import { useGetCampaignByIdQuery } from 'hooks/campaigns/queries/useGetCampaignByIdQuery';
import { useGetOneTenantQuery } from 'hooks/tenants/queries/useGetOneTenantQuery';

const SidenavDrawer = ({ sideNavGroup }: { sideNavGroup?: SideNavGroupType }): JSX.Element => {
  const { md, toggleShowSidebar, sidebarCampaignName } = useClientContext();
  const { projectId } = useParams();
  const { logout, hasPermission, userTenantId, isSuperAdmin } = useAuthContext();
  const adminMainLinks = useAdminMainLinks();
  const userMainLinks = useUserMainLinks();
  const mobileOnlyLinks = useMobileOnlyLinks();
  const headerLinks = useHeaderLinks();
  const { campaign } = useGetCampaignByIdQuery(projectId);
  const { tenant } = useGetOneTenantQuery({ id: Number(userTenantId) });
  // If in project view, keep sideNav links in the same project
  const preservedRoute = sideNavGroup === 'user' ? `/projects/${projectId}` : '';
  const hasDocumentViewPermission = hasPermission(PermissionAction.Read, 'Document', [
    { tenantId: userTenantId },
    { campaignId: Number(projectId) },
  ]);

  const campaignName = sidebarCampaignName ?? campaign?.name ?? <CircularProgress size={'1rem'} />;

  if (campaign?.enableDocumentSystem === true && hasDocumentViewPermission) {
    userMainLinks.push(rawDocumentLink);
  }

  const sideNavLinks = React.useMemo(
    () => (sideNavGroup ? (sideNavGroup === 'admin' ? adminMainLinks : userMainLinks) : []),
    [adminMainLinks, sideNavGroup, userMainLinks]
  );

  const permittedMobileLinks = React.useMemo(
    () =>
      mobileOnlyLinks.filter((mol) => {
        const permission = privateRoutes.find((route) => route.path === mol.path)?.permission;
        return (
          !permission ||
          hasPermission(permission?.action, permission?.subject, permission?.conditions)
        );
      }),
    [hasPermission, mobileOnlyLinks]
  );

  const permittedLinks = React.useMemo(
    () =>
      sideNavLinks
        .filter((snl) => {
          const permission = privateRoutes.find((route) => route.path === snl.path)?.permission;
          return (
            !permission ||
            hasPermission(permission?.action, permission?.subject, permission?.conditions)
          );
        })
        // Exclude /admin/bulletins if not a supertenant, see https://caxyinteractive.atlassian.net/browse/HR20-820
        // Filtering a second time instead of adding to lines 65-69 as this will likely be removed once the Bulletins page is functional.
        .filter((sideNavLink) => {
          if (!tenant?.isSuperTenant || process.env.REACT_APP_ENVIRONMENT === 'production') {
            return sideNavLink.path !== '/admin/bulletins';
          }
          return true; // Keep all other links
        }),
    [sideNavLinks, hasPermission, tenant]
  );

  return (
    <>
      {/*This appears to be here just to keep content from behind the top nav*/}
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleShowSidebar}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List sx={{ display: 'flex', flexDirection: 'column' }}>
        {sideNavGroup === 'admin' && isSuperAdmin && <TenantSelector visible />}
        {!md && (
          <Box sx={{ marginTop: '0.5rem' }}>
            {headerLinks.map((headerLink) => (
              <ListItemButton
                key={getUniqueKey(headerLink)}
                component={Link}
                to={headerLink.path}
                selected={location.pathname === headerLink.path}
                onClick={() => !md && toggleShowSidebar()}
              >
                <ListItemText primary={headerLink.title} />
              </ListItemButton>
            ))}
          </Box>
        )}

        {!!permittedLinks.length && (
          <>
            {!md && <Divider sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />}
            {sideNavGroup === 'user' && (
              <>
                {md ? (
                  <>
                    <Box sx={{ position: 'relative', height: 'auto', minHeight: '4.45rem' }}>
                      <Typography
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          left: '2rem',
                          right: '2rem',
                          fontWeight: '500',
                          lineHeight: '1.4375rem', // 23px
                        }}
                        variant='subtitle2'
                      >
                        {campaignName}
                      </Typography>
                    </Box>
                    <Divider sx={{ marginTop: '1.4rem', marginBottom: '2rem' }} />
                  </>
                ) : (
                  <Typography
                    sx={{
                      marginX: '0.75rem',
                      marginY: '1rem',
                      fontWeight: '500',
                    }}
                    variant='subtitle2'
                  >
                    {campaignName}
                  </Typography>
                )}
              </>
            )}
            {permittedLinks.map((sideNavLink) => (
              <ListItemButton
                key={getUniqueKey(sideNavLink)}
                component={Link}
                to={`${preservedRoute}${sideNavLink.path}`}
                selected={location.pathname.includes(sideNavLink.path)}
                onClick={() => !md && toggleShowSidebar()}
                sx={{
                  paddingX: '0.75rem',
                }}
              >
                <ListItemText
                  primary={sideNavLink.title}
                  primaryTypographyProps={
                    location.pathname.includes(sideNavLink.path)
                      ? { style: { fontWeight: 600 } }
                      : {}
                  }
                />
              </ListItemButton>
            ))}
          </>
        )}
        {!md && !!permittedMobileLinks.length && (
          <>
            <Divider sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            {permittedMobileLinks.map(
              (sideNavLink) =>
                !sideNavLink.external && (
                  <ListItemButton
                    key={getUniqueKey(sideNavLink)}
                    component={Link}
                    to={sideNavLink.path}
                    selected={location.pathname.includes(sideNavLink.path)}
                    onClick={() => !md && toggleShowSidebar()}
                    target={sideNavLink.external ? '_blank' : '_self'}
                  >
                    <ListItemText
                      primary={sideNavLink.title}
                      primaryTypographyProps={
                        location.pathname.includes(sideNavLink.path)
                          ? { style: { fontWeight: 500 } }
                          : {}
                      }
                    />
                  </ListItemButton>
                )
            )}
            <Divider sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            <ListItemButton onClick={() => logout()}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary={'Log Out'} />
            </ListItemButton>
          </>
        )}
      </List>
    </>
  );
};

export default SidenavDrawer;
