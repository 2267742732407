import { Store, User } from '__generated__/graphql';
import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { LocaleOptions } from 'luxon/src/datetime';

interface FormatAddressType {
  __typename?: string | null;
  addressOne?: string | null;
  addressTwo?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  regionText?: string | null;
  districtText?: string | null;
}

export const LOCALE_STRING = 'en-US';
export const TASK_COMPLETED_LABEL = 'Completed task';
export const QUESTION_COMPLETED_LABEL = 'Answered question';
export const NOT_APPLICABLE_LABEL = 'Not applicable';
export const NO_RESPONSE_LABEL = 'Did not respond';
export const NOT_COMPLETED_LABEL = 'Not completed';

export const ISO_DATE_FORMAT = 'yyyy-MM-dd';

export const localeTimeFormatter = (
  time?: string,
  formatOpts: DateTimeFormatOptions = DateTime.TIME_SIMPLE,
  opts?: LocaleOptions,
  placeholder = 'N/A'
) => {
  if (!time) {
    return placeholder;
  }

  const datetime =
    time.toLowerCase().includes('am') || time.toLowerCase().includes('pm')
      ? DateTime.fromFormat(time, 'hh:mm a')
      : DateTime.fromFormat(time, 'HH:mm:ss');

  if (!datetime?.isValid) {
    return placeholder;
  }

  return datetime.toLocaleString(formatOpts, opts);
};

export const formatDatePickerDate = (date?: Date | string, placeholder = 'N/A') => {
  if (!date) return placeholder;

  const dateTime =
    typeof date === 'string'
      ? DateTime.fromFormat(date, ISO_DATE_FORMAT)
      : DateTime.fromJSDate(date);
  return dateTime.toLocaleString();
};

export const formatAddress = (address: FormatAddressType): string => {
  const formattedAddress = [];

  if (address.addressOne) formattedAddress.push(address.addressOne);
  if (address.addressTwo) formattedAddress.push(address.addressTwo);
  if (address.city) formattedAddress.push(address.city);
  if (address.state) formattedAddress.push(address.state);
  if (address.country) formattedAddress.push(address.country);
  if (address.postalCode) formattedAddress.push(address.postalCode);
  if (address.regionText) formattedAddress.push(address.regionText);
  if (address.districtText) formattedAddress.push(address.districtText);

  return formattedAddress.join(', ');
};

export const formatMinutes = (minutes?: number | string) => {
  if (minutes === undefined) return;

  if (Number(minutes) > 59) {
    const hours = Math.round((Number(minutes) / 60) * 10) / 10;

    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  }

  return `${minutes} minute(s)`;
};

export const getUsersName = (user?: Pick<User, 'firstName' | 'lastName'> | null) => {
  return user ? `${user?.firstName} ${user?.lastName}` : 'N/A';
};

export const truncateText = (text: string, length = 20) => {
  if (text.length > length) {
    return `${text.slice(0, length)}...`;
  }

  return text;
};

export function convertRawDraftStringToPlainString(draftString: string) {
  try {
    return convertFromRaw(JSON.parse(draftString)).getPlainText();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(
      '[convertRawDraftStringToPlainString] Failed to convert draft.js string to plain string',
      e
    );
    return draftString;
  }
}

export const convertHTMLStringToPlainString = (richText: string): string => {
  // try catch is placed here to not break the page in case data is not convertable
  try {
    const htmlState = htmlToDraft(richText);
    const richTextContentState = ContentState.createFromBlockArray(htmlState.contentBlocks);
    const richTextEditorState: EditorState = EditorState.createWithContent(richTextContentState);
    return richTextEditorState.getCurrentContent().getPlainText();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(
      '[convertHTMLStringToPlainString] Failed to convert rich text string to plain string',
      e
    );
    return richText;
  }
};

export const convertHTMLStringToEditorState = (
  richText: string | undefined
): EditorState | undefined => {
  if (!richText) {
    return undefined;
  }

  try {
    //try catch in case json is not parseable
    const htmlState = htmlToDraft(richText);
    const richTextContentState = ContentState.createFromBlockArray(htmlState.contentBlocks);
    return EditorState.createWithContent(richTextContentState);
  } catch (e) {
    return undefined;
  }
};

export const titleCase = (str: string): string => {
  return str
    .split(' ')
    .map((word) => word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
};

export const titleCaseIgnoreSpaces = (str: string): string => {
  return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
};

export function getStoreNumberAndName(store?: Pick<Store, 'storeNumber' | 'name'> | null): string {
  return [
    ...(store?.storeNumber ? [store.storeNumber] : []),
    ...(store?.name ? [store.name] : []),
  ].join(' - ');
}

export const formatImportStatus = (status: string): string => {
  switch (status) {
    case 'success':
      return 'Complete';
    case 'processing':
      return 'Processing...';
    case 'pending':
      return 'Pending';
    case 'error':
      return 'Error';
    default:
      return status;
  }
};

export function removeTypenamesRecursively<T>(obj: T) {
  for (const key in obj) {
    if (key === '__typename') {
      delete obj[key];
    }

    if (typeof obj[key] === 'object') {
      removeTypenamesRecursively(obj[key]);
    }
  }
}
